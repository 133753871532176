// Step 1: Import React
import _ from 'lodash'
import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/layout-alive'
import {
	card,
	alive,
	hidden
} from '../../components/layout.module.css'

const AlivePage = (props) => {
	const { pageContext, data } = props;	
	const { id } = pageContext;
	const { aliveCsv, allFile } = data;
	const files = allFile.edges;
	const fileName = `alive${id}.png`
	
	const findFile = (files, fileName) => {
		for (const file of files) {
			if (_.endsWith(file.node.publicURL, fileName)) {
				return file.node.publicURL
			}
		}
	}
	
	const imgUrl = findFile(files, fileName)
	let prevId = parseInt(id) - 1;
	let nextId = parseInt(id) + 1;
	if (prevId < 10 ) { prevId = `0${prevId}`}
	if (nextId < 10 ) { nextId = `0${nextId}`}
	if (nextId === 91 ) { nextId = `01`}
	const nextFileName = `alive${nextId}.png`
	const prevUrl = `/alive/${prevId}/`
	const nextUrl = `/alive/${nextId}/`
	const nextImgUrl = findFile(files, nextFileName)
	
  return (
		<Layout pageTitle="Alive!">
	  	<div className={card}>
				<Link to={nextUrl}>
					<img src={imgUrl} alt={aliveCsv.alt} className={alive} />
				</Link>
  		</div>
	  	<footer>
				{id === "01" ? 
					<Link to={nextUrl}>Click the card for the next one</Link>
					: (<Link to={prevUrl}>Back</Link>)
				}
				<Link to={nextUrl}>Next</Link>
				<img src={nextImgUrl} alt="" className={hidden} />
	  	</footer>
		</Layout>
  )
}

export const query = graphql`
	query ($id: String) {
  	aliveCsv(id: {eq: $id}) {
			id
			alt
  	}
		allFile(filter: {extension: {eq: "png"}}) {
			edges {
				node {
					publicURL
				}
			}
		}
	}
`
	
export default AlivePage;